body {
    background-image: url("../svg/fondo_verde.svg");
    background-color: #72B500;
    background-repeat: no-repeat;
    background-size: cover;
}

.landing {
    padding: 5%;
    border: 0px solid #FFFFFF;
    font-family: Poppins;
    font-style: normal;
}
    
.landing .head {
    color: #FFFFFF;
}
    
.landing .head .title p {
    font-weight: bold;
    font-size: 28px!important;
    line-height: 130%;
    margin-bottom: 8px;
}

.landing .head .sub-title p {
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    margin-bottom: 48px;
}

.landing .body{
    width: 100%;
    border: 0px solid #fff;
    margin-top: 48px auto;
}
    
.landing .body .box {        
    width: 100%!important;
    height: 120px;
    background: #FFFFFF;
    border-radius: 10px;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    padding: 30px;
    margin: 0 0 16px 0!important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 18px 79px rgba(47, 45, 45, 0.0363771), 0px 11.3261px 19.6823px rgba(47, 45, 45, 0.0604865), 0px 2.63109px 2.95796px rgba(47, 45, 45, 0.11);
}

.box span strong {
    font-weight: 600!important;
}

.landing .foot {
    font-size: 14px;
    line-height: 130%;    
    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;
    color: #FFFFFF;
    margin-top: 48px !important;
}

.landing a:hover {
    text-decoration: none;
    color: #000000;
}