.titulo {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
}

.btn {
    background: #72B500;
    border-radius: 50px;
    color: #ffffff;
    width: 100%;
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-weight: normal;
}

.btn-erase {
    left: 70.99%;
    right: 0.85%;
    top: 87.05%;
    bottom: -2.88%;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    /* or 17px */
    text-align: right;
    text-decoration-line: underline;
    /* Textos secundarios */
    color: #54575C;
}
/*
.form-check-input {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 0px;
    top: 0px;
    /
    background: #007BFF;

    border: 1px solid #007BFF;
    box-sizing: border-box;
    border-radius: 2px;
}
*/
