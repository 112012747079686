html {
	margin: 0;
	padding: 0 ;
}

footer { 
	background-color:rgba(87.12500050663948, 86.3989582657814, 86.3989582657814, 1);
	width: 100%;
	color:rgba(255, 255, 255, 1);
	font-family: Poppins, sans-serif;
	font-size:12px;
	background:contain;
	display: flex;
}

.container{
	width: 100%;
	padding-top: 24px;
	margin-bottom: 15px;
}

.mensaje {
	margin-top: 32px;
	letter-spacing:1;
	font-size: 12px;
	text-decoration: underline;
	display: block;
	height: 40px;
	padding-left: 32px;
}

#logo { 
	display: flex;
	width:230px;
	height:40px;
	background: url(../svg/positivo.e7d0b2b8.svg) no-repeat;
	background-size: contain;
	margin: 16px auto 10px 32px;	
}

.unlink{
	height: 50px;
	display: flex;
	flex-direction: row;
	width: 100%;	
	padding-left: 32px;
}

.imagenlink{
	width: 50px;
	display: block;
}	

.etiquetalink{
	padding-left: 4px;
	font-weight: bold 200;
	letter-spacing: 1;
	padding-top: 8px;
}

.etiquetalink a{
	text-decoration: none;
	color: white;
}

#iconley{
	background: url(../svg/book.svg) no-repeat;
	background-size: contain;
	height: 40px;
	
}

#iconpreguntas{
	background: url(../svg/message-question-ask.svg) no-repeat;
	background-size: contain;
	height: 40px;
}

#iconcontactenos{
	background: url(../svg/email.svg) no-repeat;
	background-size: contain;
	height: 40px;
}


.cc{
	width: 100%;
	letter-spacing:0.1;
	margin: 16px auto 16px auto;	
	padding: 20px;
	display: block;
}

.cc p{
	text-align: center;
}

.box{
	background-color:rgba(102.00000151991844, 163.00000548362732, 10.000000353902578, 1);
	width:280px;
	height:80px;
	border-radius:10px;
	margin: 16px 0 0 32px;
	padding: 5px;
}

.phoneParent {
	display: flex;
	align-items: center;
}

.phoneParent a{
    color: #ffffff;
    text-decoration: none;
    background-color: transparent;
}

.fonoconsultas{
	text-align:left;
	letter-spacing:1;	
	font-size: 18px;
	margin-bottom: 10px;
}

.numero {	
	text-align: left;
	font-size: 24px;
	letter-spacing: 2;
	font-weight: bold;
	margin-top: 10px;
}