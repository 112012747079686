@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&display=swap');

.btn-custom.disabled, .btn-custom:disabled {
    opacity: 0.80;
    background: #8F9196;
    opacity: 0.80;
    background: #8F9196;
    width: 100%;
    border-radius: 10px;
    font-size: 18px;
}
.flex-align{
    display: flex;
    justify-content: space-around;
    width: 100%;
}
.btn-cerrar{
  
    width: 100% !important;
    background-color: #ffffff !important;
    color: #0B7AC7 !important;
    text-decoration: none;
}
.btn:not(:disabled):not(.disabled),.btn:not(:disabled):not(.disabled):hover  {
    cursor: pointer;
    width: 300px;
    border-radius: 10px;
    background: #72B500;
    font-size :18px
}
.btn-custom-primary {
    background: #72B500;
    border-radius: 10px;
    border: 1.5px solid #ffffff;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: #ffffff;
    width: 100%;
    min-height: 50px;
    margin-bottom: 16px;
}

.btn-custom-primary:hover {    
    border-radius: 10px;
    border: 1.5px solid #ffffff;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: #ffffff;    
}

.btn-custom-default {
    background: #ffffff;
    border-radius: 10px;
    border: 1.5px solid #72B500;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: #72B500;
    width: 100%;
    height: 50px;
    margin-bottom: 16px;
}

.btn-custom-default:hover {
    background: #ffffff;
    border-radius: 10px;
    border: 1.5px solid #72B500;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: #72B500;
}

.file-input::-webkit-file-upload-button {
    visibility: hidden;
    width: 0px;
}

input[type="file" i] {    
    width: 100%;
    height: 40px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 200%;
    background: #FFFFFF;
    border: 0.4px solid #54575C;
    border-radius: 4px 4px 4px 4px;
}

.file-input::before {
    content: 'Subir archivo';
    padding: 15px;    
    background: #E9ECEF;
    border-right: 1px solid #54575C;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300px;
    font-size: 16px;
    color: #333333;
}

.custom-accordion {
    border: 1px solid #908E8E;
    min-height: 70px;
    width: 100%;    
    border-radius: 5px;
    margin-top: 8px!important;
    margin: 0 center;
}

.accordion-header{
    padding: 25px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    color: #54575C;
}

.eld-body .accordion-header{    
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    color: #54575C;
}

.accordion-body {
    min-height: 1px;
    padding: 25px;
}

.eld-body .accordion-body {
    min-height: 1px;
    padding: 1px 22px 10px 22px;
}

.custom-label-input{
    font-family: Roboto;
    font-style: bold;
    font-weight: 800!important;
    font-size: 18px;
    line-height: 120%;
    color: #333333;
    margin-left: 20px;
}

.custom-accordion a{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.02em;
    text-decoration-line: underline;
    color: #0B7AC7;
}

.delete-file{
    font-family: Roboto!important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px!important;
    text-align: right;
    width: 300px;
    text-decoration-line: underline;
    color: #54575C!important;
    /*position: absolute;
    right: 5%;
    top: 40%;*/    
}

.alert-custom-dark {
    color: #ffffff;
    background-color: #6B6E75;
    border-color: #c6c8ca;
}

.vertical-align {
    text-align: center;
    vertical-align: middle;
    /*border: 1px solid #ffffff;*/
}

.title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 600!important;
    font-size: 18px;
    margin-bottom: 9px;
}

.sub-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
}

.volver {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    letter-spacing: -0.06em;
    text-decoration-line: underline;
    /* Azul bonito */
    color: #0B7AC7;
}
.paet-head {    
    font-family: Poppins;
    font-style: normal;
    font-weight: 600!important;
    font-size: 26px;
    line-height: 130%;
    color: #333333;
    margin: 48px 57px 0 53px;
}

.paet-body {    
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.5px;
    color: #54575C;
    margin: 16px 57px 25px 53px;
}

.paet-body a {
    color: #0B7AC7;
}

.paet-foot {
    margin: 0px 57px 32px 53px;
}

.label-text {
    font-family: Roboto;
    font-style: bold;
    font-weight: 700!important;
    font-size: 14px;
    line-height: 23px;
    color: #333333;
}

.form-text {
    font-family: Roboto;
    font-style: bold;
    font-weight: 500!important;
    font-size: 12px;
    line-height: 19px;
    color: #54575C;
}

.text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
}

.text .blue {    
    color: #0b7ac7 !important;
    text-decoration-line: underline;
}

.text .gray {        
    color: #54575C !important;
    text-decoration-line: underline;
}

.checkState{
    margin: 1rem 3rem;
}
.checkState .section{
    display: flex;
    justify-content: center;
}
.checkState .card.formulario{
    max-width: 700px !important;
}
.checkState .card{
    width: 600px;
}

.titulo-card-estado {
    color: #333333;
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 130%;
    padding-left: 1.3rem;
    padding-top: 1.5rem;
}

.text-card-estado {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 30px;
    color: #333333;
}

.text-secundario-card-estado {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 150%;
    color: #54575C;
}

.text-secundario {
    color: #82388B;
    font-family: Poppins, sans-serif;
    font-style: normal;        
    line-height: 120%;
    padding: 0rem 1rem;
}

.eld-body {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    color: #333333;
}

.eld-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600!important;
    font-size: 20px;
    line-height: 120%;
    color: #333333;
}

.eld-subtitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 130%;
    letter-spacing: 0.5px;
    color: #54575C;
}

.eld-monto-uf {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal!important;
    font-size: 24px!important;
    line-height: 120%;
    color: #82388B;
}

.eld-monto-peso {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal!important;
    font-size: 14px!important;
    line-height: 50%;
    color: #54575C;
}

.eld-font-42 { 
    font-size: 42px!important;
}

.avertical-align {
    display: flex;
    align-items: center;
}

.comprobante-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 600!important;
    font-size: 18px;
    line-height: 21px;
    color: #333333;
}

.comprobante-detalle {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #54575C;
}

.comprobante-botones {
    padding: 0 1.25rem 0 1.25rem;
}

.space{
    width: 100%;
    height: 22px;
}
.titleModal{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.03em;
    color: #333333;
}
.modalStyle{

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 100;
    color: #54575C;
    font-size: 14px;
    line-height: 150%;
}
.parrafosModal{
    margin-bottom: 45px !important;
}

.custom-a{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.02em;
    text-decoration-line: underline;
    color: #0B7AC7;
}