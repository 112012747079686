html,
body {
	padding: 0;
	margin: 0;
	background: #ffffff;
}

@font-face {
	font-family: "Poppins";
	src: url(../fonts/Poppins.ttf);
}

@font-face {
	font-family: "Roboto";
	src: url(../fonts/Roboto.ttf);
}

a {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
}

h1 {
	font-size: 60px;
	line-height: 70, 31px;
}

h2 {
	font-size: 36px;
	line-height: 120%;
}

h3 {
	font-size: 24px;
	line-height: 36px;
}

p {
	font-size: 20px;
	line-height: 30px;
}

small {
	font-size: 16px;
}

* {
	box-sizing: border-box;
	font-weight: 400 !important;
	font-family: Roboto, sans-serif;
}

section {
	margin-top: 64px;
	position: relative;
	margin-bottom: 3rem;
	width: 100%;
}

.container-padre {
	display: inline-flex;
	justify-content: center;
}

.bloque {
	justify-content: center;
}

.bloque.desktop {
	display: flex;
}

.card {
	box-shadow: 0px 100px 58px rgba(31, 41, 35, 0.00458735),
		0px 41.346px 54.0995px rgba(31, 41, 35, 0.0109364),
		0px 19.4023px 49.8576px rgba(31, 41, 35, 0.0211526),
		0px 7.32773px 44.5609px rgba(31, 41, 35, 0.0458371),
		0px 1.03284px 36.9139px rgba(31, 41, 35, 0.0757482),
		0px -1.12437px 24.2747px rgba(31, 41, 35, 0.09);
	border-radius: 4px;
	width: 100%;
}

.card.formulario {
	max-width: 500px;
	justify-content: center;
}

.card .card-header {
	border: none;
	margin-top: 1.25rem !important;
	background-color: #ededed !important;
}

.mobile .container-title {
	height: 270px;
}

.container-title h1 {
	font-size: 60px;
}

.container-title p {
	font-family: Poppins, sans-serif;
	font-weight: 300;
	letter-spacing: 1px;
	color: #54575c;
}

.txtDesktop {
	margin: auto;
}

.green {
	color: #72b500 !important;
}
.green-a {
	color: #72b500 !important;
	text-decoration: underline;
}

.blue {
	color: #0b7ac7 !important;
}

.alert-svg {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.swal2-html-container {
	margin-top: 1.5rem !important;
	text-align: left;
	margin-bottom: 1rem;
}

.textoTerciario {
	font-weight: 300;
	font-style: normal;
	font-size: 16px;
	line-height: 18.2px;
	letter-spacing: 0.5px;
	color: #54575c;
	padding: 0.7rem 1rem;
}

.card-header-margin {
	padding: 0 1rem 1rem;
}

.swal-footer {
	color: #0056b3;
	text-decoration: underline;
}

.circle {
	padding: 3px 15px;
	color: #ffffff;
	border-radius: 50px;
	font-size: 2.5rem;
}

.textoIzquierda {
	text-align: left;
}

.green-circle {
	background-color: #8ec045;
}

.orange-circle {
	background-color: #f7931e;
}

.mobile {
	display: none !important;
}

.green-tabla {
	background-color: #72b500 !important;
	color: #ffffff;
}

.disclaimer-modal {
	font-family: "Roboto";
	font-size: 12px;
	text-align: justify;
	font-style: normal;
	font-weight: 300;
	line-height: 120%;
	color: #54575c !important;
}
/**********************MODAL**********************/
.modal-header,
.modal-footer {
	border: none;
}
.modal-header {
	padding-bottom: 0.5rem;
}
.modal-title {
	margin-top: 1rem;
	color: #333333;
}
.modal-content p {
	font-size: 16px;
}
.modal-footer {
	display: flex;
	justify-content: center;
}
.modal-footer button {
	width: 160px;
}
/**********************FIN MODAL**********************/
/**********************Formulario**********************/

.form-control {
	display: block;
	line-height: 2em;
	margin: 0;
	padding-left: 10px;
	width: 100%;
	font-size: medium;
	border: 1px solid #f4f4f4;
	background-color: #f4f4f4;
	border-radius: 5px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-weight: 500;
}

.form-control:focus {
	border: 1px solid #2c7ac9;
}

.label-checkbox {
	font-weight: 500;
	font-style: normal;
	font-size: 20px;
	line-height: 120%;
	color: #333333;
}

.control-label {
	display: block;
	position: absolute;
	opacity: 0;
	bottom: 1.9rem;
	color: #54575c;
	transition: 0.2s ease-in-out transform;
	font-size: 10px;
}

.form-group input {
	border: #999999 1px solid;
	border-radius: 4px;
}

.form-group select {
	border: #999999 1px solid;
	border-radius: 4px;
	width: 100%;
}
.form-group small {
	margin: 0;
	/*font-size: 12px;*/
}

.select label {
	width: 100%;
	font-size: 14px;
}

.btn {
	background: #72b500;
	border-radius: 50px;
	color: #ffffff;
	width: 100%;
	font-family: Poppins, sans-serif;
	font-size: 20px;
	font-weight: normal;
}

.btn:hover {
	color: #ffffff;
}

.whiteBtn {
	background: #ffffff !important;
	border: 1px solid #0b7ac7;
	color: #0b7ac7;
}

.whiteBtn:hover {
	color: #0b7ac7 !important;
}

.blueBtn {
	background: #0b7ac7 !important;
}

.align-svg {
	vertical-align: text-bottom;
}

.textoSecundario {
	color: #82388b;
	font-family: Poppins, sans-serif;
	font-style: normal;
	font-weight: 600 !important;
	font-size: 20px;
	line-height: 120%;
	padding: 0rem 1rem;
}
.textoSecundario-white {
	color: #ffffff;
	font-family: Poppins, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 120%;
}
.text-green {
	color: #72b500;
	font-family: Poppins, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 120%;
	padding: 0rem 1rem;
}
.alerta {
	font-size: 14px;
	line-height: 18px;
	font-weight: 300;
	font-family: Roboto, sans-serif;
	font-style: normal;
}

.textoTitulo {
	color: #333333;
	font-family: Poppins, sans-serif;
	font-style: normal;
	font-weight: 600 !important;
	font-size: 24px;
	line-height: 130%;
	margin-bottom: 1rem;
	padding-top: 2rem;
	padding-left: 1rem;
}
.textoTitulo-white {
	color: #ffffff;
	font-family: Poppins, sans-serif;
	font-style: normal;
	font-weight: 600 !important;
	font-size: 44px;
	line-height: 130%;
}

.textoBeneficiario {
	color: #333333;
	font-family: Poppins, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 130%;
	margin-bottom: 1rem;
}

.terminosYcondiciones {
	font-size: 12px;
	color: #5b5b5b;
	line-height: 16px;
}

.medio {
	display: flex !important;
}
small {
	color: #6c757d;
}
small.is-invalid {
	color: #ed1c24;
}
small .is-valid {
	color: #1bb934;
}
input[type="checkbox"] + label {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
}

section.sec-landing {
	margin-top: 0;
	position: relative;
	margin-bottom: 3rem;
	width: 100%;
}
/**********************Fin formulario**********************/

/**********************Preguntas frecuentes**********************/
.faqs h2 {
	margin: 2rem 0;
}
.faqs .card {
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.faqs .card .card-header {
	color: #54575c;
	font-weight: 600;
	font-size: 18px;
}
.faqs .card p {
	font-size: 18px;
}

/**********************FIN Preguntas frecuentes**********************/

/**********************Inicio Footer**********************/

.footer * {
	font-size: 10px;
	color: #ffffff;
}

.disclaimer {
	font-size: 10px;
}

.footer p {
	padding: 1.5rem;
	margin: 0;
}

.footer {
	background: #575656;
	position: relative;
	bottom: 0;
}

.footer .mensaje {
	font-size: 18px;
	text-decoration: none;
}
.footer a {
	font-size: 16px;
}
.footer .phoneParent .fonoconsultas {
	font-size: 14px;
	line-height: 18px;
	font-family: Poppins, sans-serif;
	font-weight: 400 !important;
}

.footer .phoneParent .numero {
	font-size: 18px;
	line-height: 18px;
	font-family: Poppins, sans-serif;
	font-weight: 500 !important;
}
.footer .phoneParent .numero svg {
	width: 19px;
	margin: 2px 5px 2px 0;
}

.footer .box {
	padding: 0.9rem !important;
	width: max-content;
}
.footer .cc {
	padding: 0 !important;
	margin: 0 !important;
}

/**********************Fin Footer**********************/

@media (max-width: 62em) {
	.medio {
		display: none !important;
	}

	section {
		margin-top: 0px !important;
		margin-bottom: 0;
	}
}

@media (max-width: 48em) {
	.mobile {
		display: flex !important;
		padding: 0;
	}

	.desktop {
		display: none !important;
	}

	.container-padre {
		display: block;
	}

	.container-title {
		padding: 2.1rem;
	}

	.bloque {
		display: flex;
	}

	.card {
		width: 100%;
	}

	section .row {
		margin: 0 !important;
	}

	.container-title h1 {
		font-size: 2rem;
	}
}

@media (max-width: 36em) {
	body {
		width: 100% !important;
	}

	h1 {
		font-size: 24px !important;
		line-height: 28px;
	}

	h2 {
		font-size: 20px !important;
		line-height: 120%;
	}

	h3 {
		font-size: 14px !important;
		line-height: 21px;
	}

	p {
		font-size: 14px !important;
		line-height: 21px;
	}

	small {
		font-size: 12px !important;
	}

	.form {
		padding: 0;
	}

	.volver img {
		margin: 0 !important;
	}
}

@media (max-width: 25.875em) {
	.container-title {
		padding: 2.2rem;
	}
}
@media (max-width: 25.688em) {
	.container-title {
		padding: 2.1rem;
	}
}

@media (max-width: 23.5em) {
	.container-title {
		padding: 0.8rem;
	}
}

@media (max-width: 23.43em) {
	.container-title {
		padding: 0.4rem;
	}
}

@media (max-width: 20em) {
	.container-title {
		padding: 0rem;
	}
	.calculadora .container-title p {
		font-size: 14px !important;
		line-height: 12px !important;
	}
}

.blue-a {
	color: #007bff !important;
	text-decoration: underline;
}

.afiliados {
	margin: 16px 0 16px 0;
	padding: 5px 0 5px 24px;
}
small.small-formCuentas {
	font-size: 16px !important;
	font-weight: normal !important;
}

