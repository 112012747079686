.calculadora .abrirApv img {
    width: 5%;
}

.calculadora .container-title h1 {
    margin: 1.5rem;
}

.wizardForm img {
    margin-right: -8rem;
}

.wizardForm .card-body {
    margin-top: 1.2rem;
}

.modal-wizard {
    color: #0B7AC7;
    cursor: pointer;
}

    .wizardForm .card-body p {
        font-family: Poppins, sans-serif;
        font-size: 18px;
        margin-left: 14px;
    }

.wizardForm form input {
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 4px;
    width: 449px;
    height: 52.88px;
}

.wizardForm form .form-check-label input {
    height: 16px;
    width: 16px;
    margin-right: 8px;
    position: relative;
}

.wizardForm form small {
    margin-top: 5px;
    color: #54575C;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}

.wizardForm form button {
    margin: 2rem 1rem 0.8rem 1rem;
    width: 240px;
    height: 50px;
}

.regimenes {
    margin-top: 10rem;
}

.btn-cerrar {
    display: inline-block !important;
    width: 30% !important;
    margin-top: 1.5rem !important;
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    color: #0B7AC7 !important;
    text-decoration: underline;
}

    .regimenes h2 {
        font-size: 36px;
        margin-bottom: 5rem;
    }

    .regimenes .card {
        max-height: 488px;
        height: 400px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
    }

    .regimenes .card-header {
        font-weight: 500;
        font-size: 32px;
        line-height: 24px;
        color: #333333;
        padding: 2rem;
        border: none;
    }

    .regimenes .card-text {
        font-family: Poppins, sans-serif;
        font-size: 20px;
        line-height: 40px;
    }

    .regimenes .card-link {
        color: #0B7AC7;
        font-size: 18px;
        line-height: 24px;
    }

@media (max-width: 36em) {
    .wizardForm form input {
        width: 100%;
    }

    .wizardForm .card-body {
        padding: 0.25rem;
    }

    .calculadora .header {
        z-index: 1;
    }

    .background {
        position: absolute;
        top: -64px;
        right: -15px;
        left: -15px;
    }

    .calculadora .container-title h1 {
        color: #FFFFFF;
    }

    .calculadora .container-title p {
        color: #FFFFFF;
        font-weight: 300;
        font-weight: normal;
    }

    .regimenes h2 {
        margin-bottom: 2rem;
    }

    .regimenes .card {
        height: fit-content;
        margin-bottom: 1rem;
    }

    .regimenes .card-header {
        font-size: 24px;
        line-height: 24px;
    }

    .regimenes .card-body {
        padding-top: 0;
    }

    .regimenes .card-text {
        font-size: 14px;
        line-height: 24px;
    }

    .regimenes .card-link {
        font-size: 16px;
    }

    .wizardForm form .form-check-label {
        padding: 0;
    }
}
