@font-face {
    font-family: "Poppins";
    src: local("Poppins-Medium"), url(../fonts/Poppins-Medium.ttf);
}

.modal-content .modal-title {
    text-align: center;
    width: 300px;
    height: 47px;
    left: 63px;
    top: 262px;
}

.modal-content .modal-header Modal.Title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.03em;
    /* Textos primarios */
    color: #333333;
}

.modal-content .modal-body p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
}

.modal-content .modal-body h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */
    /* Textos secundarios */
    color: #333333;
}

.modal-content .modal-footer {
    justify-content: center;
}

.modal-content .modal-title {
    width: 100%;
    text-align: center;
}

.text-left{
    text-align: left;
}

.body-ges{
    padding: 15px;
    line-height: 150%;
}