@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
	padding: 0;
	margin: 0;
	background: #ffffff;
}

@font-face {
	font-family: "Poppins";
	src: url(/static/media/Poppins.8081832f.ttf);
}

@font-face {
	font-family: "Roboto";
	src: url(/static/media/Roboto.03523cf5.ttf);
}

a {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
}

h1 {
	font-size: 60px;
	line-height: 70, 31px;
}

h2 {
	font-size: 36px;
	line-height: 120%;
}

h3 {
	font-size: 24px;
	line-height: 36px;
}

p {
	font-size: 20px;
	line-height: 30px;
}

small {
	font-size: 16px;
}

* {
	box-sizing: border-box;
	font-weight: 400 !important;
	font-family: Roboto, sans-serif;
}

section {
	margin-top: 64px;
	position: relative;
	margin-bottom: 3rem;
	width: 100%;
}

.container-padre {
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-justify-content: center;
	        justify-content: center;
}

.bloque {
	-webkit-justify-content: center;
	        justify-content: center;
}

.bloque.desktop {
	display: -webkit-flex;
	display: flex;
}

.card {
	box-shadow: 0px 100px 58px rgba(31, 41, 35, 0.00458735),
		0px 41.346px 54.0995px rgba(31, 41, 35, 0.0109364),
		0px 19.4023px 49.8576px rgba(31, 41, 35, 0.0211526),
		0px 7.32773px 44.5609px rgba(31, 41, 35, 0.0458371),
		0px 1.03284px 36.9139px rgba(31, 41, 35, 0.0757482),
		0px -1.12437px 24.2747px rgba(31, 41, 35, 0.09);
	border-radius: 4px;
	width: 100%;
}

.card.formulario {
	max-width: 500px;
	-webkit-justify-content: center;
	        justify-content: center;
}

.card .card-header {
	border: none;
	margin-top: 1.25rem !important;
	background-color: #ededed !important;
}

.mobile .container-title {
	height: 270px;
}

.container-title h1 {
	font-size: 60px;
}

.container-title p {
	font-family: Poppins, sans-serif;
	font-weight: 300;
	letter-spacing: 1px;
	color: #54575c;
}

.txtDesktop {
	margin: auto;
}

.green {
	color: #72b500 !important;
}
.green-a {
	color: #72b500 !important;
	text-decoration: underline;
}

.blue {
	color: #0b7ac7 !important;
}

.alert-svg {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.swal2-html-container {
	margin-top: 1.5rem !important;
	text-align: left;
	margin-bottom: 1rem;
}

.textoTerciario {
	font-weight: 300;
	font-style: normal;
	font-size: 16px;
	line-height: 18.2px;
	letter-spacing: 0.5px;
	color: #54575c;
	padding: 0.7rem 1rem;
}

.card-header-margin {
	padding: 0 1rem 1rem;
}

.swal-footer {
	color: #0056b3;
	text-decoration: underline;
}

.circle {
	padding: 3px 15px;
	color: #ffffff;
	border-radius: 50px;
	font-size: 2.5rem;
}

.textoIzquierda {
	text-align: left;
}

.green-circle {
	background-color: #8ec045;
}

.orange-circle {
	background-color: #f7931e;
}

.mobile {
	display: none !important;
}

.green-tabla {
	background-color: #72b500 !important;
	color: #ffffff;
}

.disclaimer-modal {
	font-family: "Roboto";
	font-size: 12px;
	text-align: justify;
	font-style: normal;
	font-weight: 300;
	line-height: 120%;
	color: #54575c !important;
}
/**********************MODAL**********************/
.modal-header,
.modal-footer {
	border: none;
}
.modal-header {
	padding-bottom: 0.5rem;
}
.modal-title {
	margin-top: 1rem;
	color: #333333;
}
.modal-content p {
	font-size: 16px;
}
.modal-footer {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
}
.modal-footer button {
	width: 160px;
}
/**********************FIN MODAL**********************/
/**********************Formulario**********************/

.form-control {
	display: block;
	line-height: 2em;
	margin: 0;
	padding-left: 10px;
	width: 100%;
	font-size: medium;
	border: 1px solid #f4f4f4;
	background-color: #f4f4f4;
	border-radius: 5px;
	box-sizing: border-box;
	font-weight: 500;
}

.form-control:focus {
	border: 1px solid #2c7ac9;
}

.label-checkbox {
	font-weight: 500;
	font-style: normal;
	font-size: 20px;
	line-height: 120%;
	color: #333333;
}

.control-label {
	display: block;
	position: absolute;
	opacity: 0;
	bottom: 1.9rem;
	color: #54575c;
	transition: 0.2s ease-in-out transform;
	font-size: 10px;
}

.form-group input {
	border: #999999 1px solid;
	border-radius: 4px;
}

.form-group select {
	border: #999999 1px solid;
	border-radius: 4px;
	width: 100%;
}
.form-group small {
	margin: 0;
	/*font-size: 12px;*/
}

.select label {
	width: 100%;
	font-size: 14px;
}

.btn {
	background: #72b500;
	border-radius: 50px;
	color: #ffffff;
	width: 100%;
	font-family: Poppins, sans-serif;
	font-size: 20px;
	font-weight: normal;
}

.btn:hover {
	color: #ffffff;
}

.whiteBtn {
	background: #ffffff !important;
	border: 1px solid #0b7ac7;
	color: #0b7ac7;
}

.whiteBtn:hover {
	color: #0b7ac7 !important;
}

.blueBtn {
	background: #0b7ac7 !important;
}

.align-svg {
	vertical-align: text-bottom;
}

.textoSecundario {
	color: #82388b;
	font-family: Poppins, sans-serif;
	font-style: normal;
	font-weight: 600 !important;
	font-size: 20px;
	line-height: 120%;
	padding: 0rem 1rem;
}
.textoSecundario-white {
	color: #ffffff;
	font-family: Poppins, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 120%;
}
.text-green {
	color: #72b500;
	font-family: Poppins, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 120%;
	padding: 0rem 1rem;
}
.alerta {
	font-size: 14px;
	line-height: 18px;
	font-weight: 300;
	font-family: Roboto, sans-serif;
	font-style: normal;
}

.textoTitulo {
	color: #333333;
	font-family: Poppins, sans-serif;
	font-style: normal;
	font-weight: 600 !important;
	font-size: 24px;
	line-height: 130%;
	margin-bottom: 1rem;
	padding-top: 2rem;
	padding-left: 1rem;
}
.textoTitulo-white {
	color: #ffffff;
	font-family: Poppins, sans-serif;
	font-style: normal;
	font-weight: 600 !important;
	font-size: 44px;
	line-height: 130%;
}

.textoBeneficiario {
	color: #333333;
	font-family: Poppins, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 130%;
	margin-bottom: 1rem;
}

.terminosYcondiciones {
	font-size: 12px;
	color: #5b5b5b;
	line-height: 16px;
}

.medio {
	display: -webkit-flex !important;
	display: flex !important;
}
small {
	color: #6c757d;
}
small.is-invalid {
	color: #ed1c24;
}
small .is-valid {
	color: #1bb934;
}
input[type="checkbox"] + label {
	-webkit-user-select: none;
	-o-user-select: none;
	-ms-user-select: none;
	    user-select: none;
}

section.sec-landing {
	margin-top: 0;
	position: relative;
	margin-bottom: 3rem;
	width: 100%;
}
/**********************Fin formulario**********************/

/**********************Preguntas frecuentes**********************/
.faqs h2 {
	margin: 2rem 0;
}
.faqs .card {
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.faqs .card .card-header {
	color: #54575c;
	font-weight: 600;
	font-size: 18px;
}
.faqs .card p {
	font-size: 18px;
}

/**********************FIN Preguntas frecuentes**********************/

/**********************Inicio Footer**********************/

.footer * {
	font-size: 10px;
	color: #ffffff;
}

.disclaimer {
	font-size: 10px;
}

.footer p {
	padding: 1.5rem;
	margin: 0;
}

.footer {
	background: #575656;
	position: relative;
	bottom: 0;
}

.footer .mensaje {
	font-size: 18px;
	text-decoration: none;
}
.footer a {
	font-size: 16px;
}
.footer .phoneParent .fonoconsultas {
	font-size: 14px;
	line-height: 18px;
	font-family: Poppins, sans-serif;
	font-weight: 400 !important;
}

.footer .phoneParent .numero {
	font-size: 18px;
	line-height: 18px;
	font-family: Poppins, sans-serif;
	font-weight: 500 !important;
}
.footer .phoneParent .numero svg {
	width: 19px;
	margin: 2px 5px 2px 0;
}

.footer .box {
	padding: 0.9rem !important;
	width: -webkit-max-content;
	width: max-content;
}
.footer .cc {
	padding: 0 !important;
	margin: 0 !important;
}

/**********************Fin Footer**********************/

@media (max-width: 62em) {
	.medio {
		display: none !important;
	}

	section {
		margin-top: 0px !important;
		margin-bottom: 0;
	}
}

@media (max-width: 48em) {
	.mobile {
		display: -webkit-flex !important;
		display: flex !important;
		padding: 0;
	}

	.desktop {
		display: none !important;
	}

	.container-padre {
		display: block;
	}

	.container-title {
		padding: 2.1rem;
	}

	.bloque {
		display: -webkit-flex;
		display: flex;
	}

	.card {
		width: 100%;
	}

	section .row {
		margin: 0 !important;
	}

	.container-title h1 {
		font-size: 2rem;
	}
}

@media (max-width: 36em) {
	body {
		width: 100% !important;
	}

	h1 {
		font-size: 24px !important;
		line-height: 28px;
	}

	h2 {
		font-size: 20px !important;
		line-height: 120%;
	}

	h3 {
		font-size: 14px !important;
		line-height: 21px;
	}

	p {
		font-size: 14px !important;
		line-height: 21px;
	}

	small {
		font-size: 12px !important;
	}

	.form {
		padding: 0;
	}

	.volver img {
		margin: 0 !important;
	}
}

@media (max-width: 25.875em) {
	.container-title {
		padding: 2.2rem;
	}
}
@media (max-width: 25.688em) {
	.container-title {
		padding: 2.1rem;
	}
}

@media (max-width: 23.5em) {
	.container-title {
		padding: 0.8rem;
	}
}

@media (max-width: 23.43em) {
	.container-title {
		padding: 0.4rem;
	}
}

@media (max-width: 20em) {
	.container-title {
		padding: 0rem;
	}
	.calculadora .container-title p {
		font-size: 14px !important;
		line-height: 12px !important;
	}
}

.blue-a {
	color: #007bff !important;
	text-decoration: underline;
}

.afiliados {
	margin: 16px 0 16px 0;
	padding: 5px 0 5px 24px;
}
small.small-formCuentas {
	font-size: 16px !important;
	font-weight: normal !important;
}


.calculadora .abrirApv img {
    width: 5%;
}

.calculadora .container-title h1 {
    margin: 1.5rem;
}

.wizardForm img {
    margin-right: -8rem;
}

.wizardForm .card-body {
    margin-top: 1.2rem;
}

.modal-wizard {
    color: #0B7AC7;
    cursor: pointer;
}

    .wizardForm .card-body p {
        font-family: Poppins, sans-serif;
        font-size: 18px;
        margin-left: 14px;
    }

.wizardForm form input {
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 4px;
    width: 449px;
    height: 52.88px;
}

.wizardForm form .form-check-label input {
    height: 16px;
    width: 16px;
    margin-right: 8px;
    position: relative;
}

.wizardForm form small {
    margin-top: 5px;
    color: #54575C;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}

.wizardForm form button {
    margin: 2rem 1rem 0.8rem 1rem;
    width: 240px;
    height: 50px;
}

.regimenes {
    margin-top: 10rem;
}

.btn-cerrar {
    display: inline-block !important;
    width: 30% !important;
    margin-top: 1.5rem !important;
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    color: #0B7AC7 !important;
    text-decoration: underline;
}

    .regimenes h2 {
        font-size: 36px;
        margin-bottom: 5rem;
    }

    .regimenes .card {
        max-height: 488px;
        height: 400px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
    }

    .regimenes .card-header {
        font-weight: 500;
        font-size: 32px;
        line-height: 24px;
        color: #333333;
        padding: 2rem;
        border: none;
    }

    .regimenes .card-text {
        font-family: Poppins, sans-serif;
        font-size: 20px;
        line-height: 40px;
    }

    .regimenes .card-link {
        color: #0B7AC7;
        font-size: 18px;
        line-height: 24px;
    }

@media (max-width: 36em) {
    .wizardForm form input {
        width: 100%;
    }

    .wizardForm .card-body {
        padding: 0.25rem;
    }

    .calculadora .header {
        z-index: 1;
    }

    .background {
        position: absolute;
        top: -64px;
        right: -15px;
        left: -15px;
    }

    .calculadora .container-title h1 {
        color: #FFFFFF;
    }

    .calculadora .container-title p {
        color: #FFFFFF;
        font-weight: 300;
        font-weight: normal;
    }

    .regimenes h2 {
        margin-bottom: 2rem;
    }

    .regimenes .card {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin-bottom: 1rem;
    }

    .regimenes .card-header {
        font-size: 24px;
        line-height: 24px;
    }

    .regimenes .card-body {
        padding-top: 0;
    }

    .regimenes .card-text {
        font-size: 14px;
        line-height: 24px;
    }

    .regimenes .card-link {
        font-size: 16px;
    }

    .wizardForm form .form-check-label {
        padding: 0;
    }
}

.bg-dark {
	background-color: #000 !important;
}

.align {
	margin-top: 8px;
	margin-left: 96px;
	margin-bottom: 8px;
}
header .bg-header-c {
	background-color: #000 !important;
	width: 100%;
	height: 90px;
}
.logo {
	background-image: url(/static/media/positivo.e7d0b2b8.9e5d54f2.svg);
	background-repeat: no-repeat;
	position: absolute;
	top: 13px;
	left: 56px;
	width: 134px;
	height: 90px;
}

html {
	margin: 0;
	padding: 0 ;
}

footer { 
	background-color:rgba(87.12500050663948, 86.3989582657814, 86.3989582657814, 1);
	width: 100%;
	color:rgba(255, 255, 255, 1);
	font-family: Poppins, sans-serif;
	font-size:12px;
	background:contain;
	display: -webkit-flex;
	display: flex;
}

.container{
	width: 100%;
	padding-top: 24px;
	margin-bottom: 15px;
}

.mensaje {
	margin-top: 32px;
	letter-spacing:1;
	font-size: 12px;
	text-decoration: underline;
	display: block;
	height: 40px;
	padding-left: 32px;
}

#logo { 
	display: -webkit-flex; 
	display: flex;
	width:230px;
	height:40px;
	background: url(/static/media/positivo.e7d0b2b8.9e5d54f2.svg) no-repeat;
	background-size: contain;
	margin: 16px auto 10px 32px;	
}

.unlink{
	height: 50px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	width: 100%;	
	padding-left: 32px;
}

.imagenlink{
	width: 50px;
	display: block;
}	

.etiquetalink{
	padding-left: 4px;
	font-weight: bold 200;
	letter-spacing: 1;
	padding-top: 8px;
}

.etiquetalink a{
	text-decoration: none;
	color: white;
}

#iconley{
	background: url(/static/media/book.c03120cd.svg) no-repeat;
	background-size: contain;
	height: 40px;
	
}

#iconpreguntas{
	background: url(/static/media/message-question-ask.571970d7.svg) no-repeat;
	background-size: contain;
	height: 40px;
}

#iconcontactenos{
	background: url(/static/media/email.21ef6531.svg) no-repeat;
	background-size: contain;
	height: 40px;
}


.cc{
	width: 100%;
	letter-spacing:0.1;
	margin: 16px auto 16px auto;	
	padding: 20px;
	display: block;
}

.cc p{
	text-align: center;
}

.box{
	background-color:rgba(102.00000151991844, 163.00000548362732, 10.000000353902578, 1);
	width:280px;
	height:80px;
	border-radius:10px;
	margin: 16px 0 0 32px;
	padding: 5px;
}

.phoneParent {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

.phoneParent a{
    color: #ffffff;
    text-decoration: none;
    background-color: transparent;
}

.fonoconsultas{
	text-align:left;
	letter-spacing:1;	
	font-size: 18px;
	margin-bottom: 10px;
}

.numero {	
	text-align: left;
	font-size: 24px;
	letter-spacing: 2;
	font-weight: bold;
	margin-top: 10px;
}
.btn-custom.disabled, .btn-custom:disabled {
    opacity: 0.80;
    background: #8F9196;
    opacity: 0.80;
    background: #8F9196;
    width: 100%;
    border-radius: 10px;
    font-size: 18px;
}
.flex-align{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    width: 100%;
}
.btn-cerrar{
  
    width: 100% !important;
    background-color: #ffffff !important;
    color: #0B7AC7 !important;
    text-decoration: none;
}
.btn:not(:disabled):not(.disabled),.btn:not(:disabled):not(.disabled):hover  {
    cursor: pointer;
    width: 300px;
    border-radius: 10px;
    background: #72B500;
    font-size :18px
}
.btn-custom-primary {
    background: #72B500;
    border-radius: 10px;
    border: 1.5px solid #ffffff;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: #ffffff;
    width: 100%;
    min-height: 50px;
    margin-bottom: 16px;
}

.btn-custom-primary:hover {    
    border-radius: 10px;
    border: 1.5px solid #ffffff;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: #ffffff;    
}

.btn-custom-default {
    background: #ffffff;
    border-radius: 10px;
    border: 1.5px solid #72B500;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: #72B500;
    width: 100%;
    height: 50px;
    margin-bottom: 16px;
}

.btn-custom-default:hover {
    background: #ffffff;
    border-radius: 10px;
    border: 1.5px solid #72B500;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: #72B500;
}

.file-input::-webkit-file-upload-button {
    visibility: hidden;
    width: 0px;
}

input[type="file" i] {    
    width: 100%;
    height: 40px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 200%;
    background: #FFFFFF;
    border: 0.4px solid #54575C;
    border-radius: 4px 4px 4px 4px;
}

.file-input::before {
    content: 'Subir archivo';
    padding: 15px;    
    background: #E9ECEF;
    border-right: 1px solid #54575C;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300px;
    font-size: 16px;
    color: #333333;
}

.custom-accordion {
    border: 1px solid #908E8E;
    min-height: 70px;
    width: 100%;    
    border-radius: 5px;
    margin-top: 8px!important;
    margin: 0 center;
}

.accordion-header{
    padding: 25px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    color: #54575C;
}

.eld-body .accordion-header{    
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    color: #54575C;
}

.accordion-body {
    min-height: 1px;
    padding: 25px;
}

.eld-body .accordion-body {
    min-height: 1px;
    padding: 1px 22px 10px 22px;
}

.custom-label-input{
    font-family: Roboto;
    font-style: bold;
    font-weight: 800!important;
    font-size: 18px;
    line-height: 120%;
    color: #333333;
    margin-left: 20px;
}

.custom-accordion a{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.02em;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #0B7AC7;
}

.delete-file{
    font-family: Roboto!important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px!important;
    text-align: right;
    width: 300px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #54575C!important;
    /*position: absolute;
    right: 5%;
    top: 40%;*/    
}

.alert-custom-dark {
    color: #ffffff;
    background-color: #6B6E75;
    border-color: #c6c8ca;
}

.vertical-align {
    text-align: center;
    vertical-align: middle;
    /*border: 1px solid #ffffff;*/
}

.title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 600!important;
    font-size: 18px;
    margin-bottom: 9px;
}

.sub-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
}

.volver {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    letter-spacing: -0.06em;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    /* Azul bonito */
    color: #0B7AC7;
}
.paet-head {    
    font-family: Poppins;
    font-style: normal;
    font-weight: 600!important;
    font-size: 26px;
    line-height: 130%;
    color: #333333;
    margin: 48px 57px 0 53px;
}

.paet-body {    
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.5px;
    color: #54575C;
    margin: 16px 57px 25px 53px;
}

.paet-body a {
    color: #0B7AC7;
}

.paet-foot {
    margin: 0px 57px 32px 53px;
}

.label-text {
    font-family: Roboto;
    font-style: bold;
    font-weight: 700!important;
    font-size: 14px;
    line-height: 23px;
    color: #333333;
}

.form-text {
    font-family: Roboto;
    font-style: bold;
    font-weight: 500!important;
    font-size: 12px;
    line-height: 19px;
    color: #54575C;
}

.text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
}

.text .blue {    
    color: #0b7ac7 !important;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}

.text .gray {        
    color: #54575C !important;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}

.checkState{
    margin: 1rem 3rem;
}
.checkState .section{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.checkState .card.formulario{
    max-width: 700px !important;
}
.checkState .card{
    width: 600px;
}

.titulo-card-estado {
    color: #333333;
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 130%;
    padding-left: 1.3rem;
    padding-top: 1.5rem;
}

.text-card-estado {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 30px;
    color: #333333;
}

.text-secundario-card-estado {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 150%;
    color: #54575C;
}

.text-secundario {
    color: #82388B;
    font-family: Poppins, sans-serif;
    font-style: normal;        
    line-height: 120%;
    padding: 0rem 1rem;
}

.eld-body {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    color: #333333;
}

.eld-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600!important;
    font-size: 20px;
    line-height: 120%;
    color: #333333;
}

.eld-subtitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 130%;
    letter-spacing: 0.5px;
    color: #54575C;
}

.eld-monto-uf {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal!important;
    font-size: 24px!important;
    line-height: 120%;
    color: #82388B;
}

.eld-monto-peso {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal!important;
    font-size: 14px!important;
    line-height: 50%;
    color: #54575C;
}

.eld-font-42 { 
    font-size: 42px!important;
}

.avertical-align {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.comprobante-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 600!important;
    font-size: 18px;
    line-height: 21px;
    color: #333333;
}

.comprobante-detalle {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #54575C;
}

.comprobante-botones {
    padding: 0 1.25rem 0 1.25rem;
}

.space{
    width: 100%;
    height: 22px;
}
.titleModal{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    letter-spacing: -0.03em;
    color: #333333;
}
.modalStyle{

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 100;
    color: #54575C;
    font-size: 14px;
    line-height: 150%;
}
.parrafosModal{
    margin-bottom: 45px !important;
}

.custom-a{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.02em;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #0B7AC7;
}
@font-face {
    font-family: "Poppins";
    src: local("Poppins-Medium"), url(/static/media/Poppins-Medium.9e1bb626.ttf);
}

.modal-content .modal-title {
    text-align: center;
    width: 300px;
    height: 47px;
    left: 63px;
    top: 262px;
}

.modal-content .modal-header Modal.Title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    letter-spacing: -0.03em;
    /* Textos primarios */
    color: #333333;
}

.modal-content .modal-body p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
}

.modal-content .modal-footer {
    -webkit-justify-content: center;
            justify-content: center;
}

.modal-content .modal-title {
    width: 100%;
    text-align: center;
}

strong {
    font-weight: 600!important;
}

.active {
    text-decoration: underline;
    color: #0056b3;
}

.text-left{
    text-align: left;
}
.titulo {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
}

.btn {
    background: #72B500;
    border-radius: 50px;
    color: #ffffff;
    width: 100%;
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-weight: normal;
}

.btn-erase {
    left: 70.99%;
    right: 0.85%;
    top: 87.05%;
    bottom: -2.88%;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    /* or 17px */
    text-align: right;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    /* Textos secundarios */
    color: #54575C;
}
/*
.form-check-input {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 0px;
    top: 0px;
    /
    background: #007BFF;

    border: 1px solid #007BFF;
    box-sizing: border-box;
    border-radius: 2px;
}
*/

.stack {
    display: -webkit-flex;
    display: flex;
}
.titulo {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
}

body {
    background-image: url(/static/media/fondo_verde.bda886b3.svg);
    background-color: #72B500;
    background-repeat: no-repeat;
    background-size: cover;
}

.landing {
    padding: 5%;
    border: 0px solid #FFFFFF;
    font-family: Poppins;
    font-style: normal;
}
    
.landing .head {
    color: #FFFFFF;
}
    
.landing .head .title p {
    font-weight: bold;
    font-size: 28px!important;
    line-height: 130%;
    margin-bottom: 8px;
}

.landing .head .sub-title p {
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    margin-bottom: 48px;
}

.landing .body{
    width: 100%;
    border: 0px solid #fff;
    margin-top: 48px auto;
}
    
.landing .body .box {        
    width: 100%!important;
    height: 120px;
    background: #FFFFFF;
    border-radius: 10px;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    padding: 30px;
    margin: 0 0 16px 0!important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 18px 79px rgba(47, 45, 45, 0.0363771), 0px 11.3261px 19.6823px rgba(47, 45, 45, 0.0604865), 0px 2.63109px 2.95796px rgba(47, 45, 45, 0.11);
}

.box span strong {
    font-weight: 600!important;
}

.landing .foot {
    font-size: 14px;
    line-height: 130%;    
    -webkit-align-items: center;    
            align-items: center;
    text-align: center;
    letter-spacing: 0.1px;
    color: #FFFFFF;
    margin-top: 48px !important;
}

.landing a:hover {
    text-decoration: none;
    color: #000000;
}
@font-face {
    font-family: "Poppins";
    src: local("Poppins-Medium"), url(/static/media/Poppins-Medium.9e1bb626.ttf);
}

.modal-content .modal-title {
    text-align: center;
    width: 300px;
    height: 47px;
    left: 63px;
    top: 262px;
}

.modal-content .modal-header Modal.Title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    letter-spacing: -0.03em;
    /* Textos primarios */
    color: #333333;
}

.modal-content .modal-body p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
}

.modal-content .modal-body h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */
    /* Textos secundarios */
    color: #333333;
}

.modal-content .modal-footer {
    -webkit-justify-content: center;
            justify-content: center;
}

.modal-content .modal-title {
    width: 100%;
    text-align: center;
}

.text-left{
    text-align: left;
}

.body-ges{
    padding: 15px;
    line-height: 150%;
}
