.bg-dark {
	background-color: #000 !important;
}

.align {
	margin-top: 8px;
	margin-left: 96px;
	margin-bottom: 8px;
}
header .bg-header-c {
	background-color: #000 !important;
	width: 100%;
	height: 90px;
}
.logo {
	background-image: url(/src/assets/svg/positivo.e7d0b2b8.svg);
	background-repeat: no-repeat;
	position: absolute;
	top: 13px;
	left: 56px;
	width: 134px;
	height: 90px;
}
